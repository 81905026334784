<template>
    <q-layout>
        <!-- TOP / BRAND -->
        <!-- navbar -->
        <navbar></navbar>

        <!----------------------->
        <!-- PAGE SUB HEADER -->
        <div class="bg-mineralgreen">
            <div class="container mx-auto">
                <div class="p-5 row">
                    <div class="col-12 flex flex-col items-start">
                        <h1 class="text-white text-xl font-black font-epigrafica mt-2 uppercase">OUR EXPERT ADVICE</h1>
                        <p class="text-white">Surrounded by experts, dermatologists, aesthetic doctors and <br> skin professionals, we give you all the tips and beauty secrets...</p>
                    </div>
                </div>
            </div>
        </div>

        <!-- ABOUT -->
        <div class="white">
            <div class="container mx-auto">
                <div class="py-14 bg-cover row">
                    <div class="container mx-auto">
                        <div class="row">
                            <div class="col-12 col-sm-12 col-md-4 p-5 img-smooth-zoom">
                                <div class="static row justify-around">
                                    <q-img class="drop-shadow-xl" width="100%" height="200px" src="@/assets/media/experts-counselings/ibc-international-bio-cosmetics-conseil-experts-masque-carotte.jpg" no-spinner />
                                    <div class="bg-mineralgreen h-4 w-11/12"></div>
                                </div>
                                <div>
                                    <p class="text-mineralgreen-dark text-lg font-semibold mt-5">THE HOMEMADE CARROT ANTI-WRINKLE MASK</p>
                                    <p class="text-mineralgreen text-md text-justify break-words mb-4">
                                        Beta-carotene (which gives it its orange color) and vitamin C revitalize the skin and make it look good instantly! They boost your complexion...
                                    </p>
                                    <q-btn to="/page/en/expert-counseling/1/masque-antirides-a-la-carotte-fait-maison" size="sm" unelevated label="Read more &rarr;" class="bg-mineralgreen hover:bg-mineralgreen-dark text-white font-bold py-2 px-4 rounded-full capitalize" />
                                </div>
                            </div>

                            <div class="col-12 col-sm-12 col-md-4 p-5 img-smooth-zoom">
                                <div class="static row justify-around">
                                    <q-img class="drop-shadow-xl" width="100%" height="200px" src="@/assets/media/experts-counselings/ibc-international-bio-cosmetics-conseil-experts-levres-seches.jpg" no-spinner />
                                    <div class="bg-mineralgreen h-4 w-11/12"></div>
                                </div>
                                <div>
                                    <p class="text-mineralgreen-dark text-lg font-semibold mt-5">DRY LIPS IN HARMATTAN WEATHER ? WHAT TO DO?</p>
                                    <p class="text-mineralgreen text-md text-justify break-words mb-4">
                                        During the harmattan season, lips are very exposed and are subjected to harsh tests. When they  are chapped, they are dehydrated and irritated. Cracked, they are sometimes painful...
                                    </p>
                                    <q-btn to="/page/en/expert-counseling/2/levres-seches-en-temps-d-harmattan" size="sm" unelevated label="Read more &rarr;" class="bg-mineralgreen hover:bg-mineralgreen-dark text-white font-bold py-2 px-4 rounded-full capitalize" />
                                </div>
                            </div>

                            <div class="col-12 col-sm-12 col-md-4 p-5 img-smooth-zoom">
                                <div class="static row justify-around">
                                    <q-img class="drop-shadow-xl" width="100%" height="200px" src="@/assets/media/experts-counselings/ibc-international-bio-cosmetics-conseil-experts-cernes.jpg" no-spinner />
                                    <div class="bg-mineralgreen h-4 w-11/12"></div>
                                </div>
                                <div>
                                    <p class="text-mineralgreen-dark text-lg text-uppercase font-semibold mt-5">Natural tips to reduce dark circles</p>
                                    <p class="text-mineralgreen text-md text-justify break-words mb-4">
                                        Whether they are hereditary, caused by fatigue, stress or even a hormonal imbalance, dark circles are never welcome on a face...
                                    </p>
                                    <q-btn to="/page/en/expert-counseling/3/astuces-attenuer-les-cernes" size="sm" unelevated label="Read more &rarr;" class="bg-mineralgreen hover:bg-mineralgreen-dark text-white font-bold py-2 px-4 rounded-full capitalize" />
                                </div>
                            </div>

                            <div class="col-12 col-sm-12 col-md-4 p-5 img-smooth-zoom">
                                <div class="static row justify-around">
                                    <q-img class="drop-shadow-xl" width="100%" height="200px" src="@/assets/media/experts-counselings/ibc-international-bio-cosmetics-conseil-experts-huile-de-sesame.jpg" no-spinner />
                                    <div class="bg-mineralgreen h-4 w-11/12"></div>
                                </div>
                                <div>
                                    <p class="text-mineralgreen-dark text-lg font-semibold mt-5">THE MULTIPLE BENEFITS OF SESAME OIL'S</p>
                                    <p class="text-mineralgreen text-md text-justify break-words mb-4">
                                        Sesame oil penetrates quickly under the skin and nourishes it deeply. It makes an excellent massage oil that keeps the skin supple and elastic, protects it from microbes and accelerates  healing.
                                    </p>
                                    <q-btn to="/page/en/expert-counseling/4/astuces-huile-de-sesame" size="sm" unelevated label="Read more &rarr;" class="bg-mineralgreen hover:bg-mineralgreen-dark text-white font-bold py-2 px-4 rounded-full capitalize" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- FOOTER CONTENT -->
        <!-- footer -->
        <footerbar></footerbar>
        
    </q-layout>
</template>

<script>
import navbar from "@/views/shared/en/Navbar"
import footerbar from "@/views/shared/en/Footer"
import { useMeta } from "quasar"

export default {
    name: "ExpertsCounselings",
    components: { navbar, footerbar },
    data() {
        return {}
    },
    created () {
        useMeta({
            title: 'International Bio Cosmetics | Nos Conseils d\'Experts', // sets document title
        })
    }
};
</script>